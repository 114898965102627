import LazyImage from "@component/LazyImage";
import React from "react";
import Container from "../Container";
import Grid from "../grid/Grid";
import { Paragraph } from "../Typography";
import useNextBlurhash from "use-next-blurhash";

const SectionGallery = ({ gallery, setting }) => {
  const title = setting?.filter(
    (prop) => prop.attributes.Name === "site.title"
  )[0];

  return (
    <Container mb="70px">
      <Paragraph textAlign="center" color="text.muted" fontSize="20px">
        We believe that great architecture and design must simply improve the
        quality of living…
      </Paragraph>
      <Paragraph
        textAlign="center"
        color="text.muted"
        fontSize="20px"
        paddingBottom="10px"
      >
        – CM –
      </Paragraph>
      <Grid container spacing={6}>
        {gallery.map((item, ind) => (
          <Grid item md={6} xs={12} key={ind}>
            <LazyImage
              loader={() => item.formats.thumbnail.url}
              src={item.formats.small.url}
              blurDataURL={useNextBlurhash(item.blurhash)[0]}
              alt={`${title?.attributes.Value} ${item.name}`}
              width={600}
              height={500}
              placeholder="blur"
              className="resize-on-hover"
            />
          </Grid>
        ))}
      </Grid>
      {/* </Card> */}
    </Container>
  );
};

export default SectionGallery;
