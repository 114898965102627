import React from "react";
import Card from "../Card";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import { H1, H4, Paragraph } from "../Typography";

const SectionVision: React.FC = () => {
  return (
    <Container mb="70px">
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FlexBox
            as={Card}
            flexDirection="column"
            alignItems="center"
            p="3rem"
            height="100%"
            borderRadius={0}
            boxShadow="border"
            hoverEffect
          >
            <H1
              mb="10px"
              textAlign="center"
              className="text-uppercase"
            >
              Our Vision of Architecture
            </H1>
            <H4
              mb="20px"
              textAlign="center"
              color="text.muted"
            >
              Best Design Delivered
            </H4>
            <Paragraph textAlign="center" color="text.muted" fontSize="18px" lineHeight="40px">
              In 2004, Caymares Martin Architectural and Engineering Design
              started the company by believing that great architecture and
              design must simply improve the quality of living. The principals,
              together with a team of dedicated professionals have created a
              multitude of projects throughout south and central Florida,
              ranging from medium to large scale buildings shaping the skyline
              of many of the Florida communities.
            </Paragraph>
          </FlexBox>
        </Grid>
      </Grid>
      {/* </Card> */}
    </Container>
  );
};

export default SectionVision;
