import { GetStaticProps } from "next";
import { getSettingsApi } from "api/settings";

import SectionCategories from "../components/home/SectionCategories";

import AppLayout from "../components/layout/AppLayout";
import { getGalleryApi, getSliderApi } from "api/pages";
import SectionSlider from "@component/home/SectionSlider";
import SectionVision from "@component/home/SectionVision";
import SectionGallery from "@component/home/SectionGallery";
import { getCategoriesApi } from "api/categories";

const IndexPage = ({ setting, sliders, categories, gallery }) => {
  return (
    <main>
      <SectionSlider sliders={sliders} setting={setting} />
      <SectionVision />
      <SectionCategories categories={categories} setting={setting} />
      <SectionGallery gallery={gallery} setting={setting} />
    </main>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  // Fetch data from external API
  const setting = await getSettingsApi();

  const sliders = await getSliderApi();

  const categories = await getCategoriesApi();

  const gallery = await getGalleryApi();

  // Pass data to the page via props
  return {
    props: { setting, sliders, categories, gallery, title: "Home" },
  };
};

IndexPage.layout = AppLayout;

export default IndexPage;
