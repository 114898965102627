import NukaCarousel from "nuka-carousel";
import React from "react";

export interface CarouselProps {
  visibleSlides?: number;
  infinite?: boolean;
  autoPlay?: boolean;
  autoplayInterval?: number;
  speed?: number;
  showDots?: boolean;
  spacing?: number;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  visibleSlides,
  infinite,
  autoPlay,
  autoplayInterval,
  speed,
  showDots,
  spacing,
}) => {
  return (
    <NukaCarousel
      autoplay={autoPlay}
      autoplayInterval={autoplayInterval}
      speed={speed}
      cellSpacing={spacing}
      dragging={false}
      pauseOnHover={false}
      slidesToShow={visibleSlides}
      withoutControls={!showDots}
      wrapAround={infinite}
    >
      {React.Children.map(children, (child, ind) => (
        <div key={ind}>{child}</div>
      ))}
    </NukaCarousel>
  );
};

Carousel.defaultProps = {
  visibleSlides: 5,
  infinite: false,
  autoPlay: false,
  autoplayInterval: 5000,
  speed: 1000,
  showDots: false,
  spacing: 1.5,
};

export default Carousel;
