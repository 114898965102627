import NextImage, { ImageProps } from "next/image";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  SpaceProps,
} from "styled-system";

type LazyImageProps = ImageProps & BorderProps & SpaceProps & ColorProps;

const LazyImage = styled(({ borderRadius, ...props }: LazyImageProps) => {
  return (
    <NextImage
      {...props}
      sizes="100vw"
      style={{
        width: "100%",
        height: "auto",
      }}
    />
  );
})<ImageProps>`
  display: block;

  ${color}
  ${space}
  ${border}
`;

export default LazyImage;
