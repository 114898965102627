import LazyImage from "@component/LazyImage";
import Link from "next/link";
import React from "react";
import Card from "../Card";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import { H3, Paragraph } from "../Typography";
import useNextBlurhash from "use-next-blurhash";

const SectionCategories = ({ categories, setting }) => {
  const title = setting?.filter(
    (prop) => prop.attributes.Name === "site.title"
  )[0];

  return (
    <Container mb="70px">
      <Grid container spacing={6}>
        {categories.map((item, ind) => (
          <Grid item md={4} xs={12} key={ind}>
            <Link href={item.attributes.Slug}>
              <FlexBox
                as={Card}
                flexDirection="column"
                alignItems="center"
                height="100%"
                borderRadius={0}
                boxShadow="border"
                hoverEffect
              >
                <LazyImage
                  loader={() =>
                    item.attributes.Photo.data.attributes.formats.thumbnail.url
                  }
                  src={item.attributes.Photo.data.attributes.url}
                  blurDataURL={
                    useNextBlurhash(
                      item.attributes.Photo.data.attributes.blurhash
                    )[0]
                  }
                  alt={`${title?.attributes.Value} ${item.attributes.Photo.data.attributes.name}`}
                  width={384}
                  height={318}
                  placeholder="blur"
                  className="resize-on-hover"
                />
                <H3
                  mt="20px"
                  mb="10px"
                  textAlign="center"
                  className="text-uppercase"
                >
                  {item.attributes.Name}
                </H3>
                <Paragraph
                  textAlign="center"
                  color="text.muted"
                  paddingX="3rem"
                  paddingBottom="3rem"
                  fontSize="20px"
                >
                  {item.attributes.Description}
                </Paragraph>
              </FlexBox>
            </Link>
          </Grid>
        ))}
      </Grid>
      {/* </Card> */}
    </Container>
  );
};

export default SectionCategories;
